import { useMemo } from "react";
import { useRecoilState } from "recoil";

import {
  isAccessSMSChannelForTANState,
  profileState,
  tanChannelState
} from "@states/profile.state";
import { useAsyncSelector } from "@hooks/useAsyncSelector";

export const useChannelSelect = () => {
  const [tanChannel, setTanChannel] = useRecoilState(
    tanChannelState("loggedInUser")
  );

  const [, profile] = useAsyncSelector(profileState("loggedInUser"));

  const [, isAccessSMSChannelForTAN] = useAsyncSelector(
    isAccessSMSChannelForTANState("loggedInUser")
  );

  const radioItems = useMemo(
    () => [
      {
        label: `TAN per SMS an ${profile?.mobile ?? ""} senden`,
        value: "sms",
        disabled: !isAccessSMSChannelForTAN
      },
      {
        label: `TAN per E-Mail an ${profile?.email ?? ""} senden`,
        value: "email"
      }
    ],
    [profile?.mobile, profile?.email, isAccessSMSChannelForTAN]
  );

  return {
    tanChannel,
    setTanChannel,
    radioItems
  };
};
