import { LockOutlined } from "@ant-design/icons";
import { Space } from "antd";
import { Suspense } from "react";

import { FormInModal } from "@components/form/inputs/form-fields/FormInModal";
import { InputField } from "@components/form/inputs/form-fields/InputField";
import { LoadingHintText } from "@components/layout/loading-indicator/loading-hint-text";
import {
  SActionButtonsWrapper,
  SButton
} from "@styled-components/form/SButtons";

import TextWithLineBreaks from "@src/utils/format/TextWithLineBreaks";
import type TanChannel from "smavesto.core/lib/types/dto/tan/TanChannel";
import { ChannelSelect } from "./ChannelSelect";
import { useTanForm } from "./useTanForm";

export interface TanModalFormProps {
  destroyModal: () => void;
  updateData: (
    auth: { userUri: string; tan: string; channel: TanChannel },
    destroyTanModal: () => void
  ) => Promise<void>;
  customDescription?: string;
}

const TanModalForm: React.FunctionComponent<TanModalFormProps> = ({
  destroyModal,
  updateData,
  customDescription
}) => {
  const {
    form,
    onFinish,
    isSubmit,
    tanDescription,
    tanLoading,
    isGenerateTan,
    onGenerateTan,
    tan,
    setTan,
    otherChannelName,
    otherChannelIsAvailable
  } = useTanForm(destroyModal, updateData);

  return (
    <FormInModal form={form} onFinish={onFinish} withoutHeader>
      <Space
        direction="vertical"
        align="center"
        size={12}
        style={{ width: "100%", color: "inherit" }}
      >
        <h2 style={{ color: "inherit", margin: 0 }}>Sicherheitsabfrage</h2>

        <LockOutlined style={{ fontSize: 32 }} />

        <p style={{ margin: 0, padding: "0 44px", textAlign: "center" }}>
          {customDescription ? (
            <>
              {customDescription} <TextWithLineBreaks text={tanDescription} />.
              .
            </>
          ) : (
            <>
              Zum Speichern Ihrer gewünschten Änderung benötigen wir im nächsten
              Schritt die Eingabe eines Sicherheitscodes{" "}
              <TextWithLineBreaks text={tanDescription} />.
            </>
          )}
        </p>

        {!isGenerateTan && (
          <SButton type="primary" loading={tanLoading} onClick={onGenerateTan}>
            TAN anfordern
          </SButton>
        )}

        {isGenerateTan && (
          <InputField
            label="TAN"
            name="tan"
            inputProps={{
              autoFocus: true,
              onChange: e => setTan(e.target.value)
            }}
          />
        )}

        {otherChannelIsAvailable && (
          <>
            <h3 style={{ color: "inherit", margin: 0 }}>
              Alternative {otherChannelName}:
            </h3>

            <Suspense fallback={<LoadingHintText />}>
              <ChannelSelect />
            </Suspense>
          </>
        )}
      </Space>

      <SActionButtonsWrapper style={{ margin: 0, marginTop: 32 }}>
        <SButton
          className="reset"
          onClick={destroyModal}
          data-testid="close-income-costs-modal"
        >
          Abbrechen
        </SButton>

        <SButton
          htmlType="submit"
          type="primary"
          disabled={isSubmit || !tan}
          loading={isSubmit}
        >
          TAN bestätigen
        </SButton>
      </SActionButtonsWrapper>
    </FormInModal>
  );
};

export default TanModalForm;
