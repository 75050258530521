import { Collapse } from "antd";
import styled from "styled-components";

import { RadioGroup } from "@components/form/inputs/form-fields/RadioGroup";

import { useChannelSelect } from "./useChannelSelect";

export function ChannelSelect() {
  const { tanChannel, setTanChannel, radioItems } = useChannelSelect();

  return (
    <SCollapse ghost>
      <SCollapse.Panel
        key={0}
        header="Ich habe keinen Zugriff auf meine Telefonnummer, um die SMS-TAN zu erhalten."
      >
        <RadioGroup
          name="channel"
          direction="vertical"
          initialValue={tanChannel}
          onChange={e => setTanChannel(e.target.value)}
          items={radioItems}
        />
      </SCollapse.Panel>
    </SCollapse>
  );
}

export const SCollapse = styled(Collapse)`
  color: inherit;

  && .ant-collapse-header {
    color: inherit;
    padding: 0;
  }

  && .ant-collapse-content {
    color: inherit;
  }

  && .ant-collapse-content > .ant-collapse-content-box {
    padding: 2px 16px;
  }
`;
