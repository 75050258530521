interface TextWithLineBreaksProps {
  text: string;
}

const TextWithLineBreaks: React.FunctionComponent<TextWithLineBreaksProps> = ({
  text
}) => {
  return text.split("\n").map((line, index) => (
    <>
      {line}
      {index < text.split("\n").length - 1 && <br />}
    </>
  ));
};

export default TextWithLineBreaks;
