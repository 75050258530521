import React from "react";

import type { ICompBaseProps } from "@src/interfaces/jsx.interface";

interface IProps extends ICompBaseProps {
  loadingText?: string;
}

export const LoadingHintText: React.FC<IProps> = props => (
  <span
    className="text-color"
    style={{ fontSize: 12, fontWeight: "bold", ...props.style }}
  >
    {props.loadingText || "Bitte warten - Daten werden geladen"}
  </span>
);
