import type { OpenModalFunc } from "@theme/hook/useThemedModal";
import type { FC } from "react";

import type TanChannel from "smavesto.core/lib/types/dto/tan/TanChannel";
import TanModalForm from "./tan/TanModalForm";

export const onModalFormOpen =
  (
    openModal: OpenModalFunc,
    ModalForm: FC<{ destroyModal: () => void; userUri?: string }>,
    modalWidth?: number,
    additionalProps?: any
  ) =>
  () => {
    const inst = openModal("confirm", {
      width: modalWidth ?? "80%",
      centered: true,
      cancelText: "Abbrechen",
      modalRender: () => (
        <ModalForm {...additionalProps} destroyModal={() => inst.destroy()} />
      )
    });

    return inst;
  };

export const openTanModalForm = (
  openModal: OpenModalFunc,
  updateData: (
    {
      userUri,
      tan,
      channel
    }: { userUri: string; tan: string; channel: TanChannel },
    destroyTanModal: () => void
  ) => Promise<void>,
  customDescription?: string
) => {
  const inst = openModal("confirm", {
    width: 670,
    centered: true,
    modalRender: () => (
      <TanModalForm
        customDescription={customDescription}
        destroyModal={() => inst.destroy()}
        updateData={updateData}
      />
    )
  });
};
